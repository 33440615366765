import React from "react";
import Layout from "@lekoarts/gatsby-theme-minimal-blog/src/components/layout";
import Link from "../../components/Link";

const FestPlan = () => {
  return (
    <Layout>
      <h1>
        <i>FestPlan</i>: Datenschutz
      </h1>
      <p>Stand: 03.02.2023</p>

      <h1>Datenschutzerklärung für die FestPlan App</h1>

      <p>
        Wir, die Entwickler der FestPlan App, legen großen Wert auf den Schutz Ihrer privaten Informationen. Sie
        behandeln Ihre persönlichen Daten mit Diskretion und in Übereinstimmung mit den geltenden Datenschutzgesetzen
        und dieser Datenschutzerklärung.
      </p>

      <p>
        Gewöhnlich ist die Verwendung unserer App ohne die Angabe von persönlichen Daten möglich. Sollten auf unseren
        Seiten jedoch Informationen wie Name, Adresse oder E-Mail-Adresse erhoben werden, geschieht dies ausschließlich
        auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Genehmigung nicht an Dritte weitergegeben.
      </p>

      <p>
        Wir möchten darauf hinweisen, dass die Übertragung von Daten im Internet, wie zum Beispiel bei der Kommunikation
        per E-Mail, Sicherheitslücken aufweisen kann. Es ist nicht möglich, die Daten vor unbefugtem Zugriff durch
        Dritte vollständig zu schützen.
      </p>

      <p>
        Die Verarbeitung der personenbezogenen Daten erfolgt auf Grundlage der gesetzlichen Bestimmungen des § 96 Abs 3
        TKG sowie des Art 6 Abs 1 lit a (Einwilligung) und/oder f (berechtigtes Interesse) der DSGVO.
      </p>

      <h2>Server-Log-Files</h2>
      <p>
        Der Anbieter der Seiten sammelt und speichert automatisch Informationen in sogenannten Server-Protokolldateien,
        die Ihr Browser automatisch an uns weiterleitet. Hierbei handelt es sich um:
        <ul>
          <li>verwendetes Betriebssystem</li>
          <li>Browsertyp und Browserversion</li>
          <li>Referrer URL</li>
          <li>Uhrzeit der Serveranfrage</li>
          <li>Hostname des zugreifenden Rechners</li>
        </ul>
        Diese Daten können nicht einer bestimmten Person zugeordnet werden. Es wird keine Verbindung dieser Daten mit
        anderen Datenquellen hergestellt. Wir behalten uns das Recht vor, diese Daten nachträglich zu überprüfen, wenn
        es konkrete Hinweise auf eine rechtswidrige Verwendung gibt.
      </p>

      <h2>Google Firebase</h2>
      <p>
        Wir nutzen Google Firebase, eine Plattform von Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043,
        USA), um Daten zu speichern und zu verarbeiten. Mit Hilfe von Google Firebase sammeln und analysieren wir
        Nutzungsdaten unserer App. Diese Daten werden auf Servern von Google in den USA aufbewahrt.
      </p>

      <p>
        Google hat sich dem EU-US Privacy Shield unterworfen, einer Vereinbarung zwischen der EU und den USA, die die
        Einhaltung des europäischen Datenschutzniveaus gewährleistet. Weitere Informationen zum Datenschutz bei Google
        Firebase finden Sie hier:{" "}
        <a href="https://firebase.google.com/support/privacy/">https://firebase.google.com/support/privacy/</a>
      </p>

      <h2>Ihre Rechte</h2>
      <p>
        Sie haben das Recht, kostenlos Auskunft über Ihre gespeicherten personenbezogenen Daten zu erhalten. Außerdem
        können Sie jederzeit die Berichtigung, Sperrung oder Löschung dieser Daten verlangen. Bei Fragen zu
        personenbezogenen Daten können Sie sich jederzeit unter der angegebenen Adresse im Impressum an uns wenden.
      </p>

      <h2>Änderung unserer Datenschutzbestimmungen</h2>
      <p>
        Wir behalten uns das Recht vor, diese Datenschutzerklärung jederzeit anzupassen, um sicherzustellen, dass sie
        den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen widerzuspiegeln,
        beispielsweise bei der Einführung neuer Dienstleistungen. Bei Ihrem nächsten Besuch gilt dann die aktualisierte
        Datenschutzerklärung.
      </p>
      <h2>Fragen an den Datenschutzbeauftragten</h2>
      <p>
        Wenn Sie Anliegen bezüglich Datenschutz haben, können Sie uns gerne per E-Mail kontaktieren oder sich direkt an
        die Person in unserer Organisation wenden, die für den Datenschutz zuständig ist:
      </p>
      <p>Malte Peters</p>
      <p>Torstraße 99</p>
      <p>10119 Berlin</p>
      <Link href="mailto:app.festplan+privacy@gmail.com">app.festplan+privacy@gmail.com</Link>
    </Layout>
  );
};

export default FestPlan;
